import axios from 'axios'

class additionalReferral {
    additionalReferral(formData) {
        return axios
            .post('https://admin.bshappliancesdirect.co.uk/api/user_consents/', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(response => {
                return (response.data);
            })
    }
}

export default new additionalReferral();
