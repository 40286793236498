var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseLayout", [
    _c("div", { attrs: { id: "content" } }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", { staticClass: "mb-40" }, [
          _vm._v("Engineer Additional Referral")
        ]),
        _c("div", { staticClass: "form-block additional-referral" }, [
          _c(
            "div",
            { staticClass: "form bg-white" },
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var invalid = ref.invalid
                      var reset = ref.reset
                      return [
                        _c(
                          "form",
                          {
                            ref: "additionalReferralForm",
                            staticClass: "needs-validation",
                            attrs: { novalidate: "" },
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.additionalReferralForm($event)
                              },
                              reset: function($event) {
                                $event.preventDefault()
                                return reset($event)
                              }
                            }
                          },
                          [
                            _vm.message
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "alert mb-4",
                                    class: _vm.referralFormAlertClass,
                                    attrs: { role: "alert" }
                                  },
                                  [_vm._v(" " + _vm._s(_vm.message) + " ")]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-6 form-group" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "engineer_number",
                                      rules: "required"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(v) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.engineer_number,
                                                    expression:
                                                      "engineer_number"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control form-control-lg",
                                                class: {
                                                  "is-invalid":
                                                    v.invalid && v.validated
                                                },
                                                attrs: {
                                                  type: "number",
                                                  placeholder:
                                                    "Engineer Number",
                                                  id: "engineer_number",
                                                  name: "engineer_number"
                                                },
                                                domProps: {
                                                  value: _vm.engineer_number
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.engineer_number =
                                                      $event.target.value
                                                  }
                                                }
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback text-left"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        v.errors[0]
                                                          ? "A valid Engineer Number is required"
                                                          : ""
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-6 form-group" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "job_number",
                                      rules: "required"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(v) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.job_number,
                                                    expression: "job_number"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control form-control-lg",
                                                class: {
                                                  "is-invalid":
                                                    v.invalid && v.validated
                                                },
                                                attrs: {
                                                  type: "number",
                                                  placeholder:
                                                    "Related Job Number",
                                                  id: "job_number",
                                                  name: "job_number"
                                                },
                                                domProps: {
                                                  value: _vm.job_number
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.job_number =
                                                      $event.target.value
                                                  }
                                                }
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback text-left"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        v.errors[0]
                                                          ? "A valid Job Number is required"
                                                          : ""
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-md-12 form-group" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "category",
                                      rules: "required"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(v) {
                                            return [
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.category,
                                                      expression: "category"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "custom-select alt form-control form-control-lg",
                                                  class: {
                                                    "is-invalid":
                                                      v.invalid && v.validated
                                                  },
                                                  attrs: { name: "category" },
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.category = $event
                                                        .target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "",
                                                        selected: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Appliance Category"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    _vm.categories,
                                                    function(category) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: category.id,
                                                          domProps: {
                                                            value: category.id
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                category.name
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback text-left"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        v.errors[0]
                                                          ? "category selection is required"
                                                          : ""
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("div", { staticClass: "checkbox mb-4" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.is_approved,
                                    expression: "is_approved"
                                  }
                                ],
                                staticClass: "theme-bg",
                                attrs: {
                                  type: "checkbox",
                                  name: "is_approved",
                                  id: "is_approved"
                                },
                                domProps: {
                                  checked: _vm.is_approved,
                                  checked: Array.isArray(_vm.is_approved)
                                    ? _vm._i(_vm.is_approved, null) > -1
                                    : _vm.is_approved
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.is_approved,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.is_approved = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.is_approved = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.is_approved = $$c
                                      }
                                    },
                                    function() {
                                      return _vm.onTerms
                                    }
                                  ]
                                }
                              }),
                              _c("label", { attrs: { for: "is_approved" } }, [
                                _c("span", { staticClass: "d-block mb-2" }, [
                                  _vm._v(
                                    "By ticking this box, I, the engineer, confirm that I have read the following statement to the consumer:"
                                  )
                                ]),
                                _vm._v(
                                  " As the consumer, you agree to be contacted by a member of our customer service team regarding new appliances. Your information will only be used to facilitate this one follow-up call from our Product Advice team, and no additional contact will be made without your agreement. If you change your mind and do not wish to be contacted, you can let us know by calling 01536 436313."
                                )
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-end" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "theme-bg btn btn-primary",
                                    attrs: {
                                      type: "Submit",
                                      disabled:
                                        (invalid === false && _vm.is_approved
                                          ? false
                                          : true) || _vm.loading
                                    }
                                  },
                                  [
                                    _c("span", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.loading,
                                          expression: "loading"
                                        }
                                      ],
                                      staticClass:
                                        "spinner-grow spinner-grow-sm",
                                      staticStyle: {
                                        width: "1.0625rem",
                                        height: "1.0625rem"
                                      },
                                      attrs: {
                                        role: "status",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v(" Submit ")
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }