<template>
    <BaseLayout>
        <div id="content">
            <div class="container">
                <h1 class="mb-40">Engineer Additional Referral</h1>
                <div class="form-block additional-referral">
                    <div class="form bg-white">
                        <ValidationObserver v-slot="{ invalid, reset }">
                            <form class="needs-validation" ref="additionalReferralForm"
                                @submit.prevent="additionalReferralForm" @reset.prevent="reset" novalidate>
                                <div v-if="message" :class="referralFormAlertClass" class="alert mb-4" role="alert">
                                    {{ message }}
                                </div>
                                <div class="row">
                                    <div class="col-md-6 form-group">
                                        <ValidationProvider name="engineer_number" rules="required" v-slot="v">
                                            <input type="number" v-model="engineer_number"
                                                class="form-control form-control-lg" placeholder="Engineer Number"
                                                id="engineer_number" name="engineer_number"
                                                v-bind:class="{ 'is-invalid': v.invalid && v.validated }" />
                                            <div class="invalid-feedback text-left">
                                                {{ v.errors[0] ? 'A valid Engineer Number is required' : '' }}
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <ValidationProvider name="job_number" rules="required" v-slot="v">
                                            <input type="number" v-model="job_number"
                                                class="form-control form-control-lg" placeholder="Related Job Number"
                                                id="job_number" name="job_number"
                                                v-bind:class="{ 'is-invalid': v.invalid && v.validated }" />
                                            <div class="invalid-feedback text-left">
                                                {{ v.errors[0] ? 'A valid Job Number is required' : '' }}
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <ValidationProvider name="category" rules="required" v-slot="v">
                                            <select name="category" v-model="category"
                                                class="custom-select alt form-control form-control-lg"
                                                v-bind:class="{ 'is-invalid': v.invalid && v.validated }">
                                                <option value="" selected>Appliance Category</option>
                                                <option v-for="category in categories" :value="category.id"
                                                    :key="category.id">
                                                    {{ category.name }}
                                                </option>
                                            </select>
                                            <div class="invalid-feedback text-left">
                                                {{ v.errors[0] ? 'category selection is required' : '' }}
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="checkbox mb-4">
                                    <input type="checkbox" class="theme-bg" name="is_approved" id="is_approved"
                                        v-model="is_approved" :checked="is_approved" @change="() => onTerms" />
                                    <label for="is_approved"><span class="d-block mb-2">By ticking this box, I, the
                                            engineer, confirm that I have read the following statement to the
                                            consumer:</span> As the consumer, you agree to be contacted by a member of
                                        our customer service team regarding new appliances. Your information will only
                                        be used to facilitate this one follow-up call from our Product Advice team, and
                                        no additional contact will be made without your agreement. If you change your
                                        mind and do not wish to be contacted, you can let us know by calling 01536
                                        436313.</label>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <button type="Submit"
                                        :disabled="(invalid === false && is_approved ? false : true) || loading"
                                        class="theme-bg btn btn-primary">
                                        <span v-show="loading" class="spinner-grow spinner-grow-sm"
                                            style="width:1.0625rem;height:1.0625rem;" role="status"
                                            aria-hidden="true"></span>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>
<script>
import BaseLayout from "@/layouts/base";
import CategoryService from "@/services/category.service";
import additionalReferralService from '../../services/additionalreferral.service';

export default {
    name: "EngineerAdditionalReferral",
    components: {
        BaseLayout
    },
    data: function () {
        return {
            engineer_number: "",
            job_number: "",
            category: '',
            categories: [],
            is_approved: false,
            loading: false,
            message: "",
            referralFormAlertClass: "alert-danger",
        };
    },
    created() {
        this.getCategories()
    },
    methods: {
        getCategories: function () {
            CategoryService.roots().then((list) => {
                this.categories = list;
            });
        },
        onTerms() {
            this.is_approved = !this.is_approved;
        },
        resetMessage() {
            setTimeout(() => {
                this.message = "";
            }, 3000);
        },
        resetRefferalForm() {
            this.engineer_number = "";
            this.job_number = "";
            this.category = "";
            this.is_approved = false;
        },
        additionalReferralForm() {
            this.loading = true;
            let form = new FormData();
            form.append("engineer_number", this.engineer_number);
            form.append("job_number", this.job_number);
            form.append('category', this.category);
            form.append('is_approved', this.is_approved);
            additionalReferralService.additionalReferral(form).then(
                () => {
                    this.loading = false;
                    this.referralFormAlertClass = "alert-success";
                    this.message = "Submitted Successfully";
                    this.resetMessage();
                    this.resetRefferalForm();
                    this.$refs.additionalReferralForm.reset();
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response && Object.values(error.response.data).join(" and ")) ||
                        error.message ||
                        error.toString();
                }
            )
        },
    }
};
</script>
<style scoped></style>